/* eslint-disable @typescript-eslint/no-explicit-any */

import { Ref, ref } from 'vue'
import { TranslationResource } from '@/types/models'
import { isUndefined, values as getObjectValues, values } from 'lodash'

interface UseTranslatableFields {
  // translations: Ref<{ [key: string]: TranslationItem }>
  translations: Ref<any>
  updateTranslation: (
    field: string,
    value: string | Event | File,
    language?: string | undefined,
    index?: number
  ) => void

  removeTranslation: (field: string, index?: number) => void
  getTranslationsForField: (field: string) => TranslationResource

  getTranslationsForFields: (fields: string[]) => {
    [key: string]: TranslationResource
  }

  translatedFields: (translatableFields: string[], translatableGroupFields: string[], lang: string) => any

  copyTranslationFromSavedEntity: (entity: any, fieldNames: string[]) => void

  isAllFormTranslated: (fieldNames: string[], languages: string[]) => boolean
  translatedField: (fieldName: string, lang: string, groupFields?: boolean) => string | string[]
}

export const useTranslatableFields = (): UseTranslatableFields => {
  const translations = ref()

  /**
   * Updates translation for a field and language.
   * Triggered on input field change.
   */
  const updateTranslation = (
    field: string,
    value: string | Event | File,
    language?: string | undefined,
    // It should be set only if field is part of a group
    index?: number
  ) => {
    language = language || 'en'
    if (value instanceof Event) {
      const target = value.target as HTMLInputElement
      value = target.value
    }

    if (!translations?.value?.[field]) {
      translations.value = {
        ...translations.value,
        ...{ [`${field}`]: typeof index !== 'undefined' ? [] : {} },
      }
    }

    const translatedValue = value instanceof File ? [value] : value

    if (typeof index !== 'undefined') {
      if (!translations.value[field][index]) {
        translations.value[field][index] = {}
      }
      translations.value[field][index][language] = translatedValue
    } else {
      translations.value[field][language] = translatedValue
    }
  }

  const removeTranslation = (
    field: string,
    // It should be set only if field is part of a group
    index?: number
  ) => {
    if (!isUndefined(index)) {
      translations.value?.[field]?.splice(index, 1)
    } else {
      delete translations.value?.[field]
    }
  }

  /**
   * Returns translations for a field, from object holding all current translation in form (translations)
   */
  const getTranslationsForField = (fieldName: string) => {
    return {
      translations: translations.value?.[fieldName],
    }
  }

  /**
   * Returns translations for a list of fields.
   */
  const getTranslationsForFields = (fieldNames: string[]) => {
    const translatedFields: { [key: string]: TranslationResource } = {}
    fieldNames.forEach((fieldName) => {
      translatedFields[fieldName] = getTranslationsForField(fieldName)
    })

    return translatedFields
  }

  const isAllFormTranslated = (fieldNames: string[], languages: string[]) => {
    return fieldNames.every((fieldName) => {
      return languages?.every((language) => {
        const fieldTranslations = getTranslationsForField(fieldName).translations
        const isGroupField = fieldTranslations && Object.keys(fieldTranslations).every((index) => !isNaN(+index))

        return !isGroupField
          ? fieldTranslations?.[language]
          : values(fieldTranslations).every((fieldItem) => fieldItem?.[language])
      })
    })
  }

  const copyTranslationFromSavedEntity = (entity: any, fieldNames: string[]) => {
    translations.value = Object.fromEntries(
      Object.entries(entity || {})
        .filter((entry: Array<any>) => fieldNames.indexOf(entry[0]) > -1)
        .map((entry: Array<any>) => [entry[0], entry[1]?.translations])
    )
  }

  /**
   * Returns the field in the selected language.
   */
  const translatedField = (fieldName: string, lang: string, groupFields?: boolean) => {
    return groupFields
      ? getObjectValues(translations.value?.[fieldName]).map(
          // eslint-disable-next-line
          (fieldValues: any) => fieldValues[lang] || ''
        )
      : translations.value?.[fieldName]?.[lang]
  }

  /**
   * Returns the fields in the currently selected language.
   */
  const translatedFields = (translatableFields: string[], translatableGroupFields: string[], lang: string): any => {
    const translatedEntity: any = {}
    translatableFields.forEach((field) => (translatedEntity[field] = translatedField(field, lang) || ''))
    translatableGroupFields.forEach((field) => (translatedEntity[field] = translatedField(field, lang, true) || []))

    return translatedEntity
  }

  return {
    copyTranslationFromSavedEntity,
    getTranslationsForField,
    getTranslationsForFields,
    isAllFormTranslated,
    removeTranslation,
    translations,
    translatedField,
    translatedFields,
    updateTranslation,
  }
}
