
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import OffersList from '@/views/campaigns/components/OffersList.vue'
import ProductCard from '@/components/common/ProductCard.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { getColor } from '@/utils'
import { NavigationCard, OfferListItem, PageCardType } from '@/types/models'
import { getImagePreview } from '@/utils/uploadsHelpers'
import { useCardsSelection } from '@/composables/useCardsSelection'
import { cardsSelectionStyle as css } from './styles'
import { translateNavigationCard } from '@/graphqlBackOffice/productBuilder'
import { useField } from 'vee-validate'
import { CarouselPageCard } from '@/types/components/UiTypes'
import { formError } from '@/components/ui/styles'
import { getAllLeadForms } from '@/views/catalog/lead-form/services/grid'
import { getTranslation } from '@/utils/translation'
import { getAllTrafficCards } from '../../views/catalog/traffic-card/services/grid'
import { isEmpty } from 'lodash'

export default defineComponent({
  components: { OffersList, ProductCard, VueDraggableNext },

  props: {
    projectId: {
      type: String,
      default: '',
    },
    selectedLanguage: {
      type: String,
      default: '',
    },
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedCardType: {
      type: String as PropType<PageCardType>,
      default: '',
    },
    cards: {
      type: Array as PropType<CarouselPageCard[]>,
      default: () => [],
    },
    availableCardTypes: {
      type: Array as PropType<PageCardType[]>,
      default: () => ['offer', 'card'],
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['card-type-changed', 'edited-card-changed'],

  setup(props, { emit }) {
    const navigationFormRef = ref()
    const {
      addEmptyCard,
      addOfferCard,
      addTrafficCard,
      addLeadFormCard,
      loadingOffers,
      editedCard,
      editedCardIndex,
      offers,
      selectedCards,
      selectEditedCard,
      removeSelectedCard,
    } = useCardsSelection(props.selectedLanguage, props.projectId)
    const offersRef = ref([] as OfferListItem[])
    const leadForms = ref<any[]>([])
    const trafficCards = ref<any[]>([])

    const { value: cardType } =
      props.availableCardTypes?.length > 1
        ? useField('cardType')
        : { value: ref(props.selectedCardType || props.availableCardTypes[0]) }
    if (props.selectedCardType || props.availableCardTypes[0]) {
      cardType.value = cardType.value || props.selectedCardType || props.availableCardTypes[0]
    }

    const translateLeadForms = () => {
      leadForms.value = leadForms.value
        .filter((x: any) => x.languages.includes(props.selectedLanguage))
        .map((x: any) => {
          let buttons = [
            {
              appearance: 'default',
              name: getTranslation(x.formButtonLabel, props.selectedLanguage),
            },
          ]
          if (x.readMoreId)
            buttons.push({
              appearance: 'outline',
              name: getTranslation(x.readMoreButtonLabel, props.selectedLanguage),
            })
          return {
            ...x,
            id: x.leadFormId,
            name: getTranslation(x.title, props.selectedLanguage),
            image: getTranslation(x.image, props.selectedLanguage),
            description: getTranslation(x.description, props.selectedLanguage),
            buttons: buttons,
          }
        })
    }

    const translateTrafficCards = () => {
      trafficCards.value = trafficCards.value
        .filter((x: any) => x.languages.includes(props.selectedLanguage))
        .map((x: any) => {
          let buttons = []
          if (!isEmpty(x.ctaButtonLabel.translations)) {
            buttons.push({
              appearance: 'default',
              name: getTranslation(x.ctaButtonLabel, props.selectedLanguage),
            })
          }
          if (x.readMoreId)
            buttons.push({
              appearance: 'outline',
              name: getTranslation(x.readMoreButtonLabel, props.selectedLanguage),
            })
          if (x.whatsappShare)
            buttons.push({
              appearance: 'whatsapp',
              name: 'Share in Whatsapp',
            })
          return {
            ...x,
            id: x.catalogName,
            name: getTranslation(x.title, props.selectedLanguage),
            image: getTranslation(x.image, props.selectedLanguage),
            description: getTranslation(x.description, props.selectedLanguage),
            buttons: buttons,
          }
        })
    }

    const getLeadForms = async () => {
      try {
        leadForms.value = await getAllLeadForms(props.projectId)

        if (props.selectedLanguage) {
          translateLeadForms()
        }
      } catch (error: any) {
        console.log(error)
      }
    }

    const getTrafficCards = async () => {
      try {
        trafficCards.value = await getAllTrafficCards(props.projectId)

        if (props.selectedLanguage) {
          translateTrafficCards()
        }
      } catch (error: any) {
        console.log(error)
      }
    }

    getLeadForms()
    getTrafficCards()

    watch(
      () => props.selectedLanguage,
      (language) => {
        offersRef.value = offers.value?.filter((offer) => offer.languages.includes(language))
        translateLeadForms()
      }
    )

    watch(
      () => loadingOffers.value,
      (loading) => {
        if (!loading) {
          offersRef.value = offers.value?.filter((offer) => offer.languages.includes(props.selectedLanguage))
          if (props.cards?.length > 0) {
            selectedCards.value =
              props.cards?.map((card) =>
                card.type === 'card'
                  ? card
                  : {
                      type: 'offer',
                      ...(offers.value?.find((offer) => offer._id === card._id) || card),
                    }
              ) || []
          }
        }
      }
    )

    watch(
      () => editedCardIndex.value,
      (index) => {
        emit('edited-card-changed', index)
      }
    )

    const cardTypes = computed(() => {
      return [
        {
          value: 'offer',
          label: 'Offer',
          icon: {
            iconName: 'alert',
            height: '33px',
            width: '43px',
            viewBox: '0 0 43 33',
            fill: cardType.value === 'offer' ? getColor('white') : getColor('secondary-gray'),
          },
        },
        {
          value: 'trafficCard',
          label: 'Traffic',
          icon: {
            iconName: 'navigation',
            height: '31px',
            width: '31px',
            fill: cardType.value === 'card' ? getColor('white') : getColor('secondary-gray'),
          },
        },
        {
          value: 'leadForm',
          label: 'Lead Form',
          icon: {
            iconName: 'contactFormIcon',
            height: '31px',
            width: '31px',
            viewBox: '0 0 54 54',
            fill: cardType.value === 'leadForm' ? getColor('white') : getColor('secondary-gray'),
          },
        },
      ]
    })

    /**
     * Details of selected card to be bound to ProductCard component.
     */
    const selectedCardDetails = (index: number) => {
      const defaultName = `Card ${index + 1}`
      if (selectedCards.value[index].type === 'card') {
        const card = selectedCards.value[index] as NavigationCard
        let cardImage = card.image?.translations?.[props.selectedLanguage]
        return {
          name: card.title?.translations?.[props.selectedLanguage] || defaultName,
          image: getImagePreview(cardImage),
          iconName: !cardImage ? 'imagePlaceholder' : '',
          selected: editedCardIndex.value === index,
        }
      } else {
        const card = selectedCards.value[index] as OfferListItem
        return {
          name: card.name || defaultName,
          image: card.image || '',
          iconName: !card.image ? 'imagePlaceholder' : '',
          selected: editedCardIndex.value === index,
          buttons: card.buttons,
        }
      }
    }

    const addNewCard = () => {
      if (addEmptyCard()) {
        emit('card-type-changed', props.availableCardTypes[0])
      }
    }

    const changeEditedCard = (index: number) => {
      cardType.value = selectedCards.value[index]?.type || props.availableCardTypes[0]
      selectEditedCard(index, cardType.value)
    }

    /**
     * Seamless preview cards.
     */
    const previewCards = computed(() =>
      selectedCards.value?.map((card) => {
        if (card.type === 'offer') {
          return offers.value?.find((offer) => offer.id === card.id)
        }

        if (card.type === 'leadForm' || card.type === 'trafficCard') {
          return card
        }

        return translateNavigationCard(card as NavigationCard, props.selectedLanguage)
      })
    )

    const cardTypeChanged = (type: PageCardType) => {
      cardType.value = type
      selectedCards.value[editedCardIndex.value] = {}
    }

    const removeSelected = (index: number) => {
      removeSelectedCard(index)
      if (selectedCards.value.length > 0) {
        changeEditedCard(0)
      }
    }

    return {
      addNewCard,
      addTrafficCard,
      addLeadFormCard,
      addOfferCard,
      cardType,
      cardTypeChanged,
      cardTypes,
      changeEditedCard,
      css: { ...css, formError },
      editedCardIndex,
      loadingOffers,
      editedCard,
      navigationFormRef,
      offers,
      previewCards,
      removeSelected,
      selectedCards,
      selectedCardDetails,
      offersRef,
      leadForms,
      trafficCards,
    }
  },
})
