import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createBlock(_component_BckGrid, { "align-items": "center" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedLanguage === language ? 'BckTextBubble' : 'BckText'), {
          key: language,
          clickable: !_ctx.disabled,
          size: "200px",
          centered: "true",
          padding: "7px",
          "font-size": "12px",
          margin: "no xSmall no",
          disabled: _ctx.disabled,
          onClick: ($event: any) => (_ctx.disabled ? null : _ctx.$emit('change', language))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getLanguageFromKey(language)), 1)
          ]),
          _: 2
        }, 1032, ["clickable", "disabled", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}