
import { computed, defineComponent, PropType, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import { OfferListItem, ReadMoreRow, SeamlessNavBarItem, SeamlessPreviewType } from '@/types/models'
import { seamlessPreviewStyle } from './styles'
import { readMoreStyle } from '@/views/campaigns/styles'
import BckOfferCard from '@/components/ui/BckOfferCard.vue'
import BckIcon from '@/components/ui/BckIcon.vue'
import BckText from '@/components/ui/BckText.vue'
import BckButton from '@/components/ui/BckButton.vue'
import BckGrid from '@/components/layout/BckGrid.vue'
import BckGridColumn from '@/components/layout/BckGridColumn.vue'
import HelperBubble from '@/views/campaigns/components/HelperBubble.vue'
import { getColor } from '@/utils'
import { ProjectColors, SeamlessPreviewSizes } from '@/types/models'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import DOMPurify from 'dompurify'
import { includes, some, values } from 'lodash'
import { margin } from '@/components/ui/styles'
import { getFullCdnUrlByAssetType } from '@/graphqlBackOffice/projects/transformers'
import Disclaimer from '@/components/common/Disclaimer.vue'

export default defineComponent({
  name: 'SeamlessPreview',

  components: {
    HelperBubble,
    BckOfferCard,
    BckIcon,
    BckButton,
    BckText,
    BckGrid,
    BckGridColumn,
    Disclaimer,
  },

  props: {
    type: {
      type: String as PropType<SeamlessPreviewType>,
      default: 'carousel',
    },
    height: {
      type: Number,
      default: 619,
    },
    domainName: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: undefined,
    },
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    offers: {
      type: Array as PropType<OfferListItem[]>,
      default: () => [],
    },
    readMoreRows: {
      type: Array as PropType<ReadMoreRow[]>,
      default: () => {
        return []
      },
    },
    text: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showHelpers: {
      type: Boolean,
      default: false,
    },
    showTerms: {
      type: Boolean,
      default: true,
    },
    navMenuItems: {
      type: Array as PropType<SeamlessNavBarItem[]>,
      default: () => [
        { name: 'Home', icon: 'homeNav' },
        { name: 'Services', icon: 'info' },
        { name: 'Offers', icon: 'shopping-cart' },
      ],
    },
    colors: {
      type: Object as PropType<ProjectColors>,
      default: () => {
        return {
          branding: '',
          menuIcons: '',
          ctaButton: '',
          ctaTextColor: '',
        }
      },
    },
    sizes: {
      type: Object as PropType<SeamlessPreviewSizes>,
      default: () => {
        return {}
      },
    },
    hasRoute: {
      type: Boolean,
      default: true,
    },
    project: {
      type: Object,
      default: undefined,
    },
    showHelperTexts: {
      type: Boolean,
      default: true,
    },
    projectBackgroundImage: {
      type: String,
      required: false,
      default: undefined,
    },
    funnelOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    sourceSeamlessPreview: {
      type: String,
      required: false,
      default: undefined,
    },
    cardDesign: {
      type: String,
      required: false,
      default: 'newCard',
    },
    flowBuilder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const cards: Ref<HTMLDivElement | null> = ref(null)
    const itemsRefs: Ref<HTMLDivElement[]> = ref([])
    const route = props.hasRoute ? useRoute() : false

    const { project } =
      route && typeof route.params.id === 'string'
        ? ProjectsService.getProjectById(route.params.id, 'cache-only')
        : props.project
        ? { project: ref(props.project) }
        : { project: ref(null) }

    const scrollCards = (direction: string) => {
      if (cards.value) {
        cards.value.scrollLeft = direction === 'left' ? cards.value?.scrollLeft - 50 : cards.value?.scrollLeft + 50
      }
    }

    const scrollToCard = async (cardNumber: number) => {
      setTimeout(() => {
        if (cards.value) {
          // 199 is the default card width
          cards.value.scrollLeft = 199 * cardNumber
        }
      }, 10)
    }

    const formatDescription = (description: string | undefined) => {
      if (!description && props.showHelperTexts) {
        return ['Item description goes here.', 'Item description goes here. Item description goes here.']
      }

      return description?.split('\n').filter((item) => item)
    }

    const seamlessColors = computed(() => {
      return {
        branding: props.colors?.branding || project.value?.branding?.brandColor || getColor('primary-blue'),
        ctaButton: props.colors?.ctaButton || project.value?.branding?.ctaColor || '',
        ctaText: props.colors?.ctaText || project.value?.branding?.ctaTextColor || '',
        menuIcons: props.colors?.menuIcons || project.value?.branding?.iconColor || getColor('white'),
      }
    })

    const projectLogo = computed(() => {
      if (props.logo) {
        return props.logo
      }

      return getFullCdnUrlByAssetType(project.value?.branding, 'logo')
    })

    const projectDomainName = computed(
      () => props.domainName || (project.value?.name ? `${project.value?.name.toLowerCase()}.online` : 'fastforward.ai')
    )

    return {
      css: { margin },
      seamlessPreviewStyle,
      formatDescription,
      scrollCards,
      getColor,
      cards,
      carouselView: computed(() => props.type === 'carousel'),
      readMorePreview: computed(() => props.type === 'readMore'),
      showDescription: computed(() => includes(['carousel', 'overflowCarousel', 'overflow'], props.type)),
      itemsRefs,
      readMoreStyle,
      projectLogo,
      projectDomainName,
      sanitizedText: computed(() => DOMPurify.sanitize(props.text, { html: true })),
      scrollToCard,
      seamlessColors,
      some,
      values,
    }
  },
})
