import gql from 'graphql-tag'

export const OFFER_BY_ID = gql`
  query offer($filter: FilterFindOneOfferInput) {
    offer(filter: $filter) {
      templateType
      languages
      offerId
      offerPrice
      offerCurrency
      tags
      whatsappShare
      createdAt
      offerImageUrls {
        translations {
          value
          lang
        }
      }
      offerVerticalImageUrls {
        translations {
          value
          lang
        }
      }
      name {
        translations {
          lang
          value
        }
      }
      description {
        translations {
          lang
          value
        }
      }
      buttons {
        _id
        name {
          translations {
            value
            lang
          }
        }
        appearance
        payload
        payloadType
        method
      }
      readMoreId {
        _id
        pageType
        name
        pageTypeDetails {
          ... on ReadMorePage {
            _id
            title {
              translations {
                lang
                value
              }
            }
            readMoreRows {
              subtitle {
                translations {
                  lang
                  value
                }
              }
              description {
                translations {
                  lang
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export const OFFERS = gql`
  query Offers($page: Int, $perPage: Int, $filter: FilterFindManyOfferInput, $sort: SortFindManyOfferInput) {
    offers(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      items {
        _id
        offerId
        name {
          translations {
            lang
            value
          }
        }
        createdAt
        offerPrice
        offerCurrency
        description {
          translations {
            lang
            value
          }
        }
        tags
        buttons {
          appearance
          name {
            translations {
              lang
              value
            }
          }
        }
        offerImageUrls {
          translations {
            lang
            value
          }
        }
        offerVerticalImageUrls {
          translations {
            lang
            value
          }
        }
        languages
      }
      count
      pageInfo {
        currentPage
        pageCount
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const OFFERS_BY_TAGS = gql`
  query OffersByTags($filter: OffersByTagsFilterInput, $page: Int, $perPage: Int) {
    offersByTags(filter: $filter, page: $page, perPage: $perPage) {
      count
      items {
        name
        offers
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const OFFERS_TAGS = gql`
  query OffersTags($projectId: String) {
    offersTags(projectId: $projectId)
  }
`

export const CAMPAIGNS = gql`
  query campaigns($filter: FilterFindManyCampaignInput, $page: Int, $perPage: Int, $sort: SortFindManyCampaignInput) {
    campaigns(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      count
      items {
        _id
        name
        platform
        languages
        template
        type
        template
        funnelIds
        createdAt
        funnels {
          _id
          status
        }
      }
    }
  }
`

export const CAMPAIGN_BY_ID = gql`
  query Campaign($filter: FilterFindOneCampaignInput) {
    campaign(filter: $filter) {
      _id
      languages
      name
      createdAt
      platform
      template
      type
      verticalScrolling
      showTermsOfService
      projectId
    }
  }
`

export const FUNNELS = gql`
  query Funnel($filter: FilterFindManyFunnelInput) {
    funnels(filter: $filter) {
      _id
      status
      name
      endDate
      stageLink
      productionLink
      startDate
    }
  }
`

export const FUNNELS_ALL_DATA = gql`
  query Funnel($filter: FilterFindManyFunnelInput) {
    funnels(filter: $filter) {
      _id
      name
      campaign {
        name
      }
      title {
        translations {
          lang
          value
        }
      }
      description {
        translations {
          value
          lang
        }
      }
      status
      startDate
      endDate
      stageLink
      productionLink
      cardsOrder
      cards {
        _id
        title {
          translations {
            lang
            value
          }
        }
        cardImageUrls {
          translations {
            value
            lang
          }
        }
        description {
          translations {
            value
            lang
          }
        }
        readMorePage {
          _id
          pageType
          name
          pageTypeDetails {
            ... on ReadMorePage {
              _id
              title {
                translations {
                  value
                  lang
                }
              }
              readMoreType
              readMoreText {
                translations {
                  value
                  lang
                }
              }
              readMoreRows {
                description {
                  translations {
                    lang
                    value
                  }
                }
                subtitle {
                  translations {
                    lang
                    value
                  }
                }
              }
            }
          }
        }
        buttonIds
        buttons {
          _id
          appearance
          payloadType
          payload
          name {
            translations {
              value
              lang
            }
          }
        }
      }
      offers {
        _id
        offerId
        name {
          translations {
            lang
            value
          }
        }

        description {
          translations {
            lang
            value
          }
        }

        buttons {
          appearance
          name {
            translations {
              lang
              value
            }
          }
        }
        offerImageUrls {
          translations {
            lang
            value
          }
        }
        offerVerticalImageUrls {
          translations {
            lang
            value
          }
        }
      }
      leadForms {
        _id
        projectId
        leadFormId
        title {
          translations {
            lang
            value
          }
        }
        description {
          translations {
            lang
            value
          }
        }
        image {
          translations {
            lang
            value
          }
        }
        readMoreButtonLabel {
          translations {
            lang
            value
          }
        }
        formButtonLabel {
          translations {
            lang
            value
          }
        }
      }

      trafficCards {
        _id
        projectId
        catalogName
        trafficType
        ctaButtonAppearance
        ctaButtonLink
        whatsappShare
        title {
          translations {
            lang
            value
          }
        }
        description {
          translations {
            lang
            value
          }
        }
        image {
          translations {
            lang
            value
          }
        }
        readMoreButtonLabel {
          translations {
            lang
            value
          }
        }
        ctaButtonLabel {
          translations {
            lang
            value
          }
        }
      }
    }
  }
`
