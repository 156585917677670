import axios from 'axios'

export const getAllTrafficCards = async (projectId: string) => {
  const { data } = await axios.post('/api/traffic-card/getAll', { projectId })

  return data
}

export const deleteTrafficCard = async (projectId: string, leadFormId: string) => {
  await axios.post('/api/traffic-card/delete', { projectId, id: leadFormId })
}

export const duplicateTrafficCard = async (projectId: string, leadFormId: string) => {
  await axios.post('/api/traffic-card/duplicate', { projectId, id: leadFormId })
}
