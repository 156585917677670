import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.productCardStyle.card(_ctx.selected, _ctx.image))
  }, [
    (_ctx.showDelete)
      ? (_openBlock(), _createBlock(_component_BckIcon, {
          key: 0,
          class: _normalizeClass(_ctx.productCardStyle.deleteIcon),
          "icon-name": "close",
          viewBox: "0 0 14 14",
          height: "14",
          width: "14",
          fill: _ctx.getColor(),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('delete')), ["stop"]))
        }, null, 8, ["class", "fill"]))
      : _createCommentVNode("", true),
    (_ctx.iconName)
      ? (_openBlock(), _createBlock(_component_BckIcon, {
          key: 1,
          "icon-name": _ctx.iconName
        }, null, 8, ["icon-name"]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(_ctx.productCardStyle.contentText)
        }, _toDisplayString(_ctx.text), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.productCardStyle.name)
    }, _toDisplayString(_ctx.name), 3)
  ], 2))
}