import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_BckTabs = _resolveComponent("BckTabs")!
  const _component_BckGridColumn = _resolveComponent("BckGridColumn")!
  const _component_BckSearchInput = _resolveComponent("BckSearchInput")!
  const _component_BckGrid = _resolveComponent("BckGrid")!
  const _component_BckOfferCard = _resolveComponent("BckOfferCard")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckFunnelContentCard = _resolveComponent("BckFunnelContentCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.singleSelection && _ctx.sortedOffers.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.offersListStyle.infoMessage)
        }, " *Drag to switch order between the offers ", 2))
      : _createCommentVNode("", true),
    (!_ctx.singleSelection && _ctx.sortedOffers.length > 0)
      ? (_openBlock(), _createBlock(_component_VueDraggableNext, {
          key: 1,
          modelValue: _ctx.sortedOffers,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortedOffers) = $event)),
          class: _normalizeClass(_ctx.offersListStyle.selectedContainer),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changed-order', _ctx.sortedOffers)))
        }, {
          default: _withCtx(() => [
            _createVNode(_TransitionGroup, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedOffers, (selectedOffer) => {
                  return (_openBlock(), _createBlock(_component_ProductCard, {
                    key: `selected${selectedOffer.id}`,
                    "show-delete": "",
                    image: selectedOffer.image,
                    name: selectedOffer.name,
                    onDelete: ($event: any) => (_ctx.$emit('toggle-offer', selectedOffer))
                  }, null, 8, ["image", "name", "onDelete"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BckGrid, {
      "align-items": "baseline",
      "justify-content": "space-between"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BckGridColumn, { size: "5/12" }, {
          default: _withCtx(() => [
            _createVNode(_component_BckTabs, {
              "tabs-data": _ctx.tabsData,
              "selected-tab": _ctx.selectedTab,
              onTabSelected: _ctx.changeSelectedTab
            }, null, 8, ["tabs-data", "selected-tab", "onTabSelected"])
          ]),
          _: 1
        }),
        _createVNode(_component_BckGridColumn, { size: "6/12" }, {
          default: _withCtx(() => [
            _createVNode(_component_BckSearchInput, {
              name: "search",
              "on-change": _ctx.updateSearchKeyword
            }, null, 8, ["on-change"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.selectedTab === 'individual')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(_ctx.offersListStyle.offersContainer)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (offer) => {
            return (_openBlock(), _createBlock(_component_BckOfferCard, {
              key: offer.id,
              offer: offer,
              padding: "no small no no",
              "show-checkbox": !_ctx.singleSelection,
              "show-enable": _ctx.editOffersEnabled,
              onChange: ($event: any) => (_ctx.$emit('toggle-offer', offer))
            }, null, 8, ["offer", "show-checkbox", "show-enable", "onChange"]))
          }), 128))
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(_ctx.offersListStyle.offersContainer)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedOffers, (groupOffers, key) => {
            return (_openBlock(), _createBlock(_component_BckFunnelContentCard, {
              key: key,
              type: "flexible",
              padding: "small",
              margin: "xSmall"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BckText, {
                  color: "primary-blue",
                  margin: "no no xxSmall",
                  weight: "bold"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(key), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_BckGrid, {
                  "multi-line": "",
                  gap: "10px"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupOffers, (offer) => {
                      return (_openBlock(), _createBlock(_component_ProductCard, {
                        key: `tag_${offer.id}`,
                        image: offer.image,
                        name: offer.name,
                        selected: offer.selected,
                        onClick: ($event: any) => (_ctx.$emit('toggle-offer', offer))
                      }, null, 8, ["image", "name", "selected", "onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ], 2))
  ], 64))
}