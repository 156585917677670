import axios from 'axios'

export const getAllLeadForms = async (projectId: string) => {
  const { data } = await axios.post('/api/lead-form/getAll', { projectId })

  return data
}

export const deleteLeadForm = async (projectId: string, leadFormId: string) => {
  await axios.post('/api/lead-form/delete', { projectId, id: leadFormId })
}

export const duplicateLeadForm = async (projectId: string, leadFormId: string) => {
  await axios.post('/api/lead-form/duplicate', { projectId, id: leadFormId })
}
