import {
  CREATE_CAMPAIGN,
  CREATE_OFFER,
  SAVE_FUNNEL,
  CAMPAIGN_BY_ID,
  OFFER_BY_ID,
  FUNNELS_ALL_DATA,
  UPDATE_OFFER,
  UPDATE_CAMPAIGN,
  DELETE_OFFER,
  DUPLICATE_OFFER,
  DELETE_FUNNEL,
  PUBLISH_FUNNELS,
  CAMPAIGNS,
  OFFERS,
  FUNNELS,
  OFFERS_BY_TAGS,
  transformCampaignFromApi,
  transformFunnelFromApi,
  transformOfferFromApi,
  transformFunnelForApi,
} from '@/graphqlBackOffice/campaigns'
import { Campaign, Funnel, Offer, ProductPage } from '@/types/models'
import { useAction, useFetch, useQueryFilters } from '@/graphqlBackOffice/composables'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import { computed, ComputedRef, watch } from 'vue'
import { useStore } from '@/store'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { CustomFilters, TableFiltersQuery } from '@/types/components'
import { ListQuery, OperatorInput, PageInfo, PaginatedList } from '@/types/graphql'
import { OfferTag } from '@/types/models/campaigns'
import { duplicateCampaign, duplicateFunnel } from '@/graphqlBackOffice/campaigns/transformers'
import { forEach, merge } from 'lodash'
import { OFFERS_TAGS } from './queries'

const computedTagsQuery = (offersQuery?: TableFiltersQuery, projectId?: string): ListQuery => {
  const query: ListQuery = {}
  query.filter = { projectId }
  query.filter = {
    ...(query.filter || {}),
    ...(offersQuery?.search ? { tags: offersQuery.search } : {}),
    ...(offersQuery?.startDate && {
      startDate: new Date(offersQuery?.startDate.toString()),
    }),
    ...(offersQuery?.endDate && {
      endDate: new Date(offersQuery?.endDate.toString()),
    }),
  }
  return query
}

class CampaignsService {
  public getOffers(projectId: string, offersQuery?: TableFiltersQuery, operatorInputs?: OperatorInput[]) {
    const { computedQuery } = useQueryFilters()
    const allFilters = computedQuery(offersQuery, ['name.translations.value', 'offerId', 'tags'], {
      projectId: projectId,
    })
    if (operatorInputs) {
      let operators = {}
      forEach(operatorInputs, (operatorInput) => (operators = merge(operators, operatorInput)))
      allFilters.filter = {
        ...(allFilters.filter || {}),
        ...operators,
      }
    }

    const { result, refetch, loading } = useFetch(OFFERS, allFilters, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    })

    const offers: ComputedRef<{
      items: Offer[]
      count: number
      pageInfo: PageInfo
    }> = computed(() => result?.value?.offers)

    const refetchOffers = (offersQuery: TableFiltersQuery, customFilters?: CustomFilters) => {
      const { computedQuery } = useQueryFilters()
      return refetch(
        computedQuery(offersQuery, ['name.translations.value', 'offerId', 'tags'], {
          ...customFilters,
          projectId: projectId,
        })
      )
    }
    return { result: offers, refetchOffers, loading }
  }

  public getOffersByTags(projectId: string, offersQuery?: TableFiltersQuery) {
    const { result, refetch, loading } = useFetch(OFFERS_BY_TAGS, computedTagsQuery(offersQuery, projectId), {
      fetchPolicy: 'cache-and-network',
    })
    const offersByTags: ComputedRef<{
      items: OfferTag[]
      count: number
      pageInfo: PageInfo
    }> = computed(() => result?.value?.offersByTags)

    const refetchTags = (offersQuery: TableFiltersQuery) => {
      return refetch(computedTagsQuery(offersQuery, projectId))
    }

    return { result: offersByTags, refetchTags, loading }
  }

  public getOfferById(id: string, fetchPolicy?: WatchQueryFetchPolicy | undefined) {
    const store = useStore()
    const { result, loading, refetch } = useFetch(
      OFFER_BY_ID,
      { filter: { _id: id } },
      {
        fetchPolicy: fetchPolicy || 'cache-only',
        nextFetchPolicy: 'cache-first',
      }
    )

    const refetchOffer = () => {
      refetch({ filter: { _id: id } })
    }

    const offer: ComputedRef<Offer | undefined> = computed(() => {
      if (!loading.value && result.value) {
        const offerResult = result.value?.offer
        return offerResult ? transformOfferFromApi(offerResult) : undefined
      }

      return undefined
    })

    const readMoreTemplate: ComputedRef<ProductPage | undefined> = computed(
      () => store.state.campaignsModule.readMoreTemplate || offer.value?.readMoreTemplate
    )

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          store.dispatch('campaignsModule/saveReadMoreTemplate', offer.value?.readMoreTemplate)
        }
      }
    )

    return { loading, offer, readMoreTemplate, refetchOffer }
  }

  public getCampaigns(campaignsQuery: TableFiltersQuery, customFilters = {}) {
    const { computedQuery } = useQueryFilters()
    const { result, refetch, loading } = useFetch(CAMPAIGNS, computedQuery(campaignsQuery, ['name'], customFilters), {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
    })

    const campaigns: ComputedRef<PaginatedList> = computed(() => {
      if (!loading.value && result.value) {
        return {
          items: result?.value?.campaigns?.items.map((campaign: any) => transformCampaignFromApi(campaign)) || [],
          count: result?.value?.count,
        }
      }

      return { items: [], count: 0 }
    })

    const refetchCampaigns = (campaignsQuery: TableFiltersQuery, customFilters = {}) => {
      return refetch(computedQuery(campaignsQuery, ['name'], customFilters))
    }

    return { result: campaigns, refetchCampaigns, loading }
  }

  public getCampaignById(id: string, fetchPolicy?: WatchQueryFetchPolicy | undefined) {
    const { result, loading } = useFetch(
      CAMPAIGN_BY_ID,
      { filter: { _id: id } },
      {
        fetchPolicy: fetchPolicy || 'cache-only',
        nextFetchPolicy: 'cache-first',
      }
    )

    const campaign: ComputedRef<Campaign | undefined> = computed(() => {
      if (!loading.value && result.value) {
        const campaignResult = result.value?.campaign
        return campaignResult ? transformCampaignFromApi(campaignResult) : undefined
      }

      return undefined
    })

    return { loading, campaign }
  }

  public async createCampaign(campaign: Campaign | undefined) {
    const { mutate: createCampaign, loading, error } = useAction(CREATE_CAMPAIGN)
    const newCampaign = await createCampaign({ campaign: campaign })
    const { networkErrorMessage } = useErrors(error)

    return {
      newCampaign: newCampaign?.data?.createCampaign?.record,
      error: newCampaign?.data?.createCampaign?.error,
      loading,
      networkErrorMessage,
    }
  }

  public async updateCampaign(campaignId: string, record: { [key: string]: string }) {
    const { mutate: updateCampaign, loading, error } = useAction(UPDATE_CAMPAIGN)
    const updatedCampaign = await updateCampaign({
      filter: { _id: campaignId },
      record,
    })
    const { networkErrorMessage } = useErrors(error)

    return {
      newCampaign: updatedCampaign?.data?.updateCampaign?.record,
      error: updatedCampaign?.data?.updateCampaign?.error,
      loading,
      networkErrorMessage,
    }
  }

  public getFunnels(query: TableFiltersQuery, customFilters = {}) {
    const { computedQuery } = useQueryFilters()
    const { result, refetch, loading } = useFetch(FUNNELS, computedQuery(query, ['name'], customFilters), {
      fetchPolicy: 'cache-and-network',
    })

    const funnels: ComputedRef<Funnel[]> = computed(() => {
      if (!loading.value && result.value) {
        return result?.value?.funnels?.map((funnel: any) => transformFunnelFromApi(funnel)) || []
      }

      return {}
    })

    const refetchFunnels = (query: TableFiltersQuery, customFilters = {}) => {
      return refetch(computedQuery(query, ['name'], customFilters))
    }

    return { result: funnels, refetchFunnels, loading }
  }

  public getFunnelById(funnelId: string, fetchPolicy?: WatchQueryFetchPolicy | undefined) {
    const { result, loading } = useFetch(
      FUNNELS_ALL_DATA,
      { filter: { _id: funnelId } },
      {
        fetchPolicy: fetchPolicy || 'cache-only',
        nextFetchPolicy: 'cache-first',
      }
    )

    const funnel: ComputedRef<Funnel | undefined> = computed(() => {
      if (!loading.value && result.value?.funnels) {
        const funnelResult = result.value?.funnels[0]
        return funnelResult ? transformFunnelFromApi(funnelResult) : undefined
      }

      return undefined
    })

    return { loading, funnel }
  }

  public saveFunnel(funnel: Funnel, campaignId: string, campaignName: string, projectId: string) {
    const { mutate: createFunnel, loading, error } = useAction(SAVE_FUNNEL)
    const mutation = createFunnel({
      funnel: transformFunnelForApi(funnel, campaignId, campaignName, projectId),
    })
    const { networkErrorMessage } = useErrors(error)

    return {
      mutation,
      loading,
      networkErrorMessage,
    }
  }

  public getRawFunnelById(funnelId: string) {
    const { result, loading } = useFetch(
      FUNNELS_ALL_DATA,
      { filter: { _id: funnelId } },
      { fetchPolicy: 'network-only' }
    )

    return { result, loading }
  }

  public getRawFunnelsForCampaign(campaignId: string) {
    const { result, loading } = useFetch(
      FUNNELS_ALL_DATA,
      { filter: { campaignId: campaignId } },
      { fetchPolicy: 'network-only' }
    )

    return { result, loading }
  }

  public async duplicateFunnel(funnel: any, campaignId: string) {
    const { mutate: createFunnel } = useAction(SAVE_FUNNEL)
    await createFunnel({
      funnel: duplicateFunnel(funnel, campaignId),
    })
  }

  public async duplicateCampaign(campaign: Campaign, projectId: string) {
    return await this.createCampaign(duplicateCampaign(campaign, projectId))
  }

  public useMutations() {
    return {
      createOffer: useAction(CREATE_OFFER),
      updateOffer: useAction(UPDATE_OFFER),
      deleteOffer: useAction(DELETE_OFFER),
      duplicateOffer: useAction(DUPLICATE_OFFER),
      updateCampaign: useAction(UPDATE_CAMPAIGN),
      deleteFunnel: useAction(DELETE_FUNNEL),
      publishFunnels: useAction(PUBLISH_FUNNELS),
    }
  }
}

export default new CampaignsService()
