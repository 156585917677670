import gql from 'graphql-tag'

export const CREATE_OFFER = gql`
  mutation CreateOffer($offer: OfferResourceInput) {
    createOffer(offer: $offer) {
      _id
      tags
      projectId
      languages
      readMoreId {
        _id
      }
    }
  }
`

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: MongoID!, $offer: OfferResourceInput) {
    updateOffer(id: $id, offer: $offer) {
      templateType
      offerId
    }
  }
`

export const DUPLICATE_OFFER = gql`
  mutation DuplicateOffer($id: MongoID!) {
    duplicateOffer(id: $id) {
      offerId
    }
  }
`

export const DELETE_OFFER = gql`
  mutation DeleteOffer($id: MongoID!) {
    deleteOffer(_id: $id) {
      record {
        offerId
      }
    }
  }
`
export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($campaign: CreateOneCampaignInput!) {
    createCampaign(record: $campaign) {
      record {
        _id
        name
      }
      error {
        message
      }
      recordId
    }
  }
`

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $record: UpdateOneCampaignInput!
    $filter: FilterUpdateOneCampaignInput
  ) {
    updateCampaign(record: $record, filter: $filter) {
      record {
        name
      }
      error {
        message
      }
    }
  }
`

export const SAVE_FUNNEL = gql`
  mutation SaveFunnel($funnel: FunnelInputTCInput) {
    saveFunnel(funnel: $funnel) {
      name
      _id
    }
  }
`

export const DELETE_FUNNEL = gql`
  mutation DeleteFunnel($id: MongoID!) {
    deleteFunnel(_id: $id) {
      recordId
    }
  }
`

export const PUBLISH_FUNNELS = gql`
  mutation PublishFunnel($funnelIds: [MongoID!]) {
    publishFunnel(funnelIds: $funnelIds) {
      name
    }
  }
`
