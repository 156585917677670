
import { computed, ComputedRef, defineComponent, PropType, Ref, ref, watch } from 'vue'
import { OfferListItem } from '@/types/models'
import BckOfferCard from '@/components/ui/BckOfferListCard.vue'
import { offersListStyle } from '../styles'
import { getColor } from '@/utils'
import { VueDraggableNext } from 'vue-draggable-next'
import { useTabs } from '@/composables/useTabs'
import BckGrid from '@/components/layout/BckGrid.vue'
import { flatten, uniq, forEach } from 'lodash'
import { useLocalSearch } from '@/composables/useLocalInputSearch'
import ProductCard from '@/components/common/ProductCard.vue'

export default defineComponent({
  components: { ProductCard, BckGrid, BckOfferCard, VueDraggableNext },
  props: {
    title: {
      type: String,
      default: '',
    },
    offers: {
      type: Array as PropType<OfferListItem[]>,
      required: true,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    editOffersEnabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['toggle-offer', 'changed-order'],

  setup(props, { emit }) {
    const sortedOffers: Ref<OfferListItem[]> = ref(props.offers.filter((offer) => offer.selected))
    const selectedOffers: ComputedRef<OfferListItem[]> = computed(() => props.offers.filter((offer) => offer.selected))
    const { changeSelectedTab, selectedTab } = useTabs('individual')

    watch(
      () => selectedOffers.value,
      (newList, oldList) => {
        // new offer was selected from the list, add it at the end of sorted offers
        if (newList.length > oldList.length) {
          const added = newList.find((item) => !oldList.find((i) => i.id === item.id))
          if (added) {
            sortedOffers.value.push(added)
          }
        }
        // offer was removed from the list, remove it from sorted offers too.
        if (newList.length < oldList.length) {
          const removed = oldList.find((item) => !newList.find((i) => i.id === item.id))
          if (removed) {
            sortedOffers.value = sortedOffers.value.filter((offer) => offer.id !== removed.id)
          }
        }

        emit('changed-order', sortedOffers.value)
      },
      { deep: true }
    )

    const selectOffer = (offer: OfferListItem) => {
      if (!offer.selected) {
        emit('toggle-offer', offer)
      }
    }

    const { updateSearchKeyword, filteredItems } = useLocalSearch(
      computed(() => props.offers),
      ['name', 'id']
    )

    const allTags: ComputedRef<string[]> = computed(() =>
      uniq(flatten(filteredItems.value.map((offer: OfferListItem) => offer.tags)))
    )

    const groupedOffers = computed(() => {
      const grouped: { [key: string]: Array<OfferListItem> } = {}
      forEach(allTags.value, (tag) => {
        if (!grouped[tag]) {
          grouped[tag] = []
        }
        forEach(filteredItems.value, (offer) => {
          if (offer.tags.indexOf(tag) > -1) {
            grouped[tag].push(offer)
          }
        })
      })
      return grouped
    })

    return {
      offersListStyle,
      getColor,
      sortedOffers,
      updateSearchKeyword,
      filteredItems,
      groupedOffers,
      changeSelectedTab,
      selectOffer,
      selectedTab,
      tabsData: [
        { key: 'individual', name: 'Individual' },
        { key: 'tags', name: 'Tags' },
      ],
    }
  },
})
