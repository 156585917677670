/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ComputedRef, Ref, ref } from 'vue'
import { filter } from 'lodash'

interface UseLocalInputSearchComposable {
  filteredItems: ComputedRef
  updateSearchKeyword: (searchKeyword: string) => void
}

export const useLocalSearch = (items: Ref<any>, searchFields: Array<string>): UseLocalInputSearchComposable => {
  const searchBy = ref('')

  const updateSearchKeyword = (searchKeyword: string) => {
    searchBy.value = searchKeyword
  }

  const filteredItems = computed(() => {
    const searchByKeyword = searchBy.value.toLowerCase()

    return filter(items.value, (item: any) => {
      return searchFields.some((fieldName) => item[fieldName].toLowerCase().indexOf(searchByKeyword) > -1)
    })
  })

  return { updateSearchKeyword, filteredItems }
}
