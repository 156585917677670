import { ref, Ref } from 'vue'
import { useRouter, useRoute, LocationQuery } from 'vue-router'

interface useTabsComposable {
  changeSelectedTab: (
    tabKey: string,
    currentQuery?: LocationQuery | undefined
  ) => void
  selectedTab: Ref<string>
}

export const useTabs = (
  initialSelectedTab: string,
  useUrl?: boolean
): useTabsComposable => {
  const router = useRouter()
  const route = useRoute()

  const selectedTab = ref(initialSelectedTab)
  if (useUrl) {
    router.push({
      query: {
        ...route.query,
        selectedTab: initialSelectedTab,
      },
    })
  }

  const changeSelectedTab = (tabKey: string, currentQuery?: LocationQuery) => {
    if (selectedTab.value === tabKey) {
      return
    }
    selectedTab.value = tabKey

    if (useUrl) {
      router.push({
        query: {
          ...currentQuery,
          selectedTab: tabKey,
        },
      })
    }
  }

  return { changeSelectedTab, selectedTab }
}
