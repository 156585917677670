import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { computed, ComputedRef, ref, Ref } from 'vue'
import { NavigationCard, OfferListItem, PageCardType } from '@/types/models'
import { transformOfferFromApi, getTranslatedOfferPreview } from '@/graphqlBackOffice/campaigns/transformers'
import { forEach, isEmpty, size } from 'lodash'
import { CarouselPageCard } from '@/types/components/UiTypes'

interface UseCardsSelection {
  loadingOffers: Ref<boolean>
  editedCardIndex: Ref<number>
  editedCard: ComputedRef<CarouselPageCard>
  offers: ComputedRef<OfferListItem[]>
  selectedCards: Ref<CarouselPageCard[]>
  selectOffer: (offer?: OfferListItem) => void
  addOfferCard: (offer: OfferListItem) => void
  addNavigationCard: (card: NavigationCard) => void
  addLeadFormCard: (card: any) => void
  addTrafficCard: (card: any) => void
  removeSelectedCard: (index: number) => void
  selectEditedCard: (index: number, cardType: PageCardType) => void
  addEmptyCard: () => boolean
}

export const useCardsSelection = (lang: string, projectId: string): UseCardsSelection => {
  const editedCardIndex = ref(0)
  const selectedCards = ref([{}])
  const editedCard: ComputedRef<CarouselPageCard> = computed(() => selectedCards.value[editedCardIndex.value])

  //TODO: temporarily added perPage 10000 since we don't have pagination on this component
  const { loading: loadingOffers, result: offersList } = CampaignsService.getOffers(projectId, { perPage: 10000 })

  /**
   * Formats the offers to offerListItems translated in selected language
   * (or an available offer language)
   */
  const offers: ComputedRef<OfferListItem[]> = computed(() => {
    return offersList.value?.items.map((offerItem) => {
      const language = offerItem.languages.indexOf(lang) > -1 ? lang : offerItem.languages[0]
      const item = transformOfferFromApi(offerItem)
      return {
        ...getTranslatedOfferPreview(item, language),
        selected: editedCard.value?.id === item.id,
      }
    })
  })

  const selectOffer = (offer?: OfferListItem) => {
    forEach(offers.value, (item) => {
      item.selected = item.id === offer?.id
    })
  }

  /**
   * Adds a new empty card.
   */
  const addEmptyCard = () => {
    // If already saved for current edited card, add a new card
    if (!isEmpty(editedCard.value) || isEmpty(selectedCards.value)) {
      selectedCards.value.push({})
      selectOffer()
      editedCardIndex.value = size(selectedCards.value) - 1

      return true
    }

    return false
  }

  /**
   * Adds an offer card to selected cards.
   */
  const addOfferCard = (offer: OfferListItem) => {
    selectOffer(offer)
    selectedCards.value[editedCardIndex.value] = {
      ...offer,
      type: 'offer',
    }
  }

  /**
   * Adds a navigation card to selected cards.
   */
  const addNavigationCard = (card: NavigationCard) => {
    selectedCards.value[editedCardIndex.value] = {
      ...card,
      type: 'card',
      id: Math.random(),
    }
  }

  const addLeadFormCard = (leadForm: any) => {
    selectedCards.value[editedCardIndex.value] = {
      ...leadForm,
      type: 'leadForm',
    }
  }

  const addTrafficCard = (trafficCard: any) => {
    selectedCards.value[editedCardIndex.value] = {
      ...trafficCard,
      type: 'trafficCard',
    }
  }

  const removeSelectedCard = (index: number) => {
    selectedCards.value.splice(index, 1)
  }

  const selectEditedCard = (index: number, cardType: PageCardType) => {
    if (cardType === 'offer') {
      selectOffer(selectedCards.value[index] as OfferListItem)
    }
    editedCardIndex.value = index
  }

  return {
    addEmptyCard,
    addOfferCard,
    addNavigationCard,
    addLeadFormCard,
    addTrafficCard,
    editedCard,
    editedCardIndex,
    loadingOffers,
    offers,
    removeSelectedCard,
    selectedCards,
    selectOffer,
    selectEditedCard,
  }
}
