import gql from 'graphql-tag'

export const GET_PRODUCT_PAGES = gql`
  query Product($filter: FilterFindOneProductInput) {
    product(filter: $filter) {
      _id
      menu {
        icon
        iconActive
        name {
          translations {
            lang
            value
          }
        }
        payload
      }
      pages {
        _id
        name
        pageId
        pageType
        productId
        canvasCoordinates {
          offsetX
          offsetY
        }
        pageTypeDetails {
          ... on ReadMorePage {
            _id
            title {
              translations {
                lang
                value
              }
            }
            buttons {
              name {
                translations {
                  lang
                  value
                }
              }
            }
            readMoreRows {
              subtitle {
                translations {
                  lang
                  value
                }
              }
              description {
                translations {
                  lang
                  value
                }
              }
            }
          }
          ... on CarouselPage {
            _id
            title {
              translations {
                lang
                value
              }
            }
            description {
              translations {
                lang
                value
              }
            }
            cardsOrder
            cards {
              _id
              cardImageUrls {
                translations {
                  lang
                  value
                }
              }
              title {
                translations {
                  lang
                  value
                }
              }
              description {
                translations {
                  lang
                  value
                }
              }
              buttons {
                _id
                payload
                payloadType
                appearance
                name {
                  translations {
                    lang
                    value
                  }
                }
              }
            }
            offers {
              _id
              offerImageUrls {
                translations {
                  lang
                  value
                }
              }
              offerVerticalImageUrls {
                translations {
                  value
                  lang
                }
              }
              name {
                translations {
                  lang
                  value
                }
              }
              description {
                translations {
                  lang
                  value
                }
              }
              buttons {
                _id
                payload
                payloadType
                appearance
                name {
                  translations {
                    lang
                    value
                  }
                }
              }
            }
          }
          ... on LearnMorePage {
            _id
            type
            title {
              translations {
                lang
                value
              }
            }
            description {
              translations {
                lang
                value
              }
            }
          }

          ... on SettingsPage {
            _id
          }
          ... on ErrorPage {
            _id
          }
        }
      }
      extensions {
        _id
        name
        entryPoint
        canvasCoordinates {
          offsetX
          offsetY
        }
      }
      project {
        name
      }
    }
  }
`

export const GET_PRODUCT_FOR_PROJECT = gql`
  query Product($filter: FilterFindOneProductInput) {
    product(filter: $filter) {
      _id
    }
  }
`

export const GET_DEFAULT_LANGUAGE_FOR_PROJECT = gql`
  query Product($filter: FilterFindOneProductInput) {
    product(filter: $filter) {
      defaultLanguage
    }
  }
`
export const GET_PRODUCT_PAGE_BY_ID = gql`
  query ProductPage($filter: FilterFindOneProductPageInput) {
    productPage(filter: $filter) {
      _id
      name
      pageType
      pwa
      pageTypeDetails {
        ... on CarouselPage {
          _id
          title {
            translations {
              lang
              value
            }
          }
          description {
            translations {
              value
              lang
            }
          }
          cardsOrder
          cards {
            _id
            title {
              translations {
                lang
                value
              }
            }
            cardImageUrls {
              translations {
                value
                lang
              }
            }
            readMorePage {
              _id
              name
              pageType
              pwa
              pageTypeDetails {
                ... on ReadMorePage {
                  _id
                  title {
                    translations {
                      value
                      lang
                    }
                  }
                  readMoreType
                  readMoreText {
                    translations {
                      value
                      lang
                    }
                  }
                  readMoreRows {
                    description {
                      translations {
                        lang
                        value
                      }
                    }
                    subtitle {
                      translations {
                        lang
                        value
                      }
                    }
                  }
                }
              }
            }
            description {
              translations {
                value
                lang
              }
            }
            buttonIds
            buttons {
              _id
              appearance
              payloadType
              payload
              method
              name {
                translations {
                  value
                  lang
                }
              }
            }
          }
          offers {
            _id
            offerId
          }
        }
      }
    }
  }
`

export const EXTENSION_BY_ID = gql`
  query ExtensionById($filter: FilterFindOneExtensionInput) {
    extension(filter: $filter) {
      name
      entryPoint
      _id
    }
  }
`
