
import { defineComponent, watchEffect } from 'vue'
import { useModal } from '@/composables/useModal'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  emits: ['loading-finished', 'close'],

  setup(props, { emit }) {
    const { isModalVisible, showModal } = useModal()
    const { loading: loadingProduct, productId } =
      ProductBuilderService.getProductIdForProject(props.id)

    watchEffect(() => {
      if (!loadingProduct.value) {
        if (!productId.value) {
          showModal()
        } else {
          emit('loading-finished', productId.value)
        }
      }
    })
    return { isModalVisible }
  },
})
