
import { productCardStyle } from './styles'
import { defineComponent } from 'vue'
import { getColor } from '@/utils'

export default defineComponent({
  props: {
    name: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['delete'],

  setup() {
    return { productCardStyle, getColor }
  },
})
