import gql from 'graphql-tag'

export const CREATE_PAGE = gql`
  mutation SaveProductPage($productPage: ProductPageInputTCInput) {
    saveProductPage(productPage: $productPage) {
      name
      cardIds
      _id
    }
  }
`

export const SAVE_PRODUCT = gql`
  mutation SaveProduct($product: ProductInputTCInput) {
    saveProduct(product: $product) {
      _id
      projectId
    }
  }
`

export const SAVE_PRODUCT_CHANGES = gql`
  mutation Mutation($pages: [ProductPageConfigurationTCInput]) {
    saveProductCanvas(pages: $pages) {
      _id
    }
  }
`

export const PREVIEW_PRODUCT = gql`
  mutation Mutation($productId: MongoID!) {
    previewProduct(productId: $productId) {
      status
    }
  }
`

export const PUBLISH_PRODUCT = gql`
  mutation Mutation($productId: MongoID!) {
    publishProduct(productId: $productId) {
      status
    }
  }
`

export const REMOVE_PAGE = gql`
  mutation Mutation($id: MongoID!) {
    deleteProductPage(_id: $id) {
      name
      productId
    }
  }
`

export const CREATE_EXTENSION = gql`
  mutation CreateExtension($record: CreateOneExtensionInput!) {
    createExtension(record: $record) {
      record {
        name
        _id
        entryPoint
      }
    }
  }
`

export const DELETE_EXTENSION = gql`
  mutation DeleteExtension($id: MongoID!) {
    deleteExtension(_id: $id) {
      record {
        _id
      }
    }
  }
`
export const UPDATE_EXTENSION = gql`
  mutation UpdateExtension(
    $record: UpdateOneExtensionInput!
    $filter: FilterUpdateOneExtensionInput
  ) {
    updateExtension(record: $record, filter: $filter) {
      record {
        name
        entryPoint
        _id
      }
    }
  }
`
