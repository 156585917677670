import {
  CREATE_PAGE,
  SAVE_PRODUCT,
  SAVE_PRODUCT_CHANGES,
  PREVIEW_PRODUCT,
  PUBLISH_PRODUCT,
  REMOVE_PAGE,
  GET_PRODUCT_PAGES,
  GET_PRODUCT_PAGE_BY_ID,
  GET_PRODUCT_FOR_PROJECT,
  GET_DEFAULT_LANGUAGE_FOR_PROJECT,
} from '@/graphqlBackOffice/productBuilder'
import { useAction, useFetch } from '@/graphqlBackOffice/composables'
import {
  CREATE_EXTENSION,
  DELETE_EXTENSION,
  UPDATE_EXTENSION,
} from '@/graphqlBackOffice/productBuilder/mutations'
import { computed, ComputedRef } from 'vue'
import { ProductPage } from '@/types/models'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import {
  transformProductPagesAndExtensions,
  transformLearnMorePageFromApi,
  transformPageForApi,
  transformPageFromApi,
} from '@/graphqlBackOffice/productBuilder/transformers'
import {
  ApiProductPage,
  ApiProductMenu,
  PageType,
} from '@/types/graphql/product'
import { WatchQueryFetchPolicy } from '@apollo/client'

class ProductBuilderService {
  public getProductPages(
    projectId: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  ) {
    const { result, loading, refetch } = useFetch(
      GET_PRODUCT_PAGES,
      { filter: { projectId: projectId } },
      { fetchPolicy, nextFetchPolicy: 'cache-first' }
    )

    const pages: ComputedRef<ApiProductPage[]> = computed(() => {
      if (!loading.value) {
        return result.value?.product?.pages
      }

      return undefined
    })
    return { loading, pages, refetch, result }
  }

  /**
   * Returns Product menu for a project.
   */
  public getProductMenu(projectId: string) {
    const { result, loading, refetch } = useFetch(
      GET_PRODUCT_PAGES,
      {
        filter: { projectId: projectId },
      },
      {
        fetchPolicy: 'cache-and-network',
      }
    )

    const menu: ComputedRef<ApiProductMenu[]> = computed(() => {
      if (!loading.value) {
        const pages = result.value?.product?.pages
        return result.value?.product?.menu?.map((menuItem: any) => {
          return {
            ...menuItem,
            pageName: pages.find(
              (page: ApiProductPage) => page._id === menuItem.payload
            )?.name,
          }
        })
      }

      return undefined
    })

    return { loading, menu, refetch }
  }

  /**
   * Returns pages and extensions for a project.
   */
  public getProductPagesAndExtensions(id: string) {
    const { loading, result, refetch } = this.getProductPages(id)

    const product = computed(() => {
      if (!loading.value) {
        const product = transformProductPagesAndExtensions(
          result.value?.product
        )
        return product ? product : undefined
      }

      return undefined
    })

    return {
      pages: computed(() => product.value?.pages),
      result: product,
      refetch,
      loading,
    }
  }

  /**
   * Returns TOS and FAQs pages for a project.
   */
  public getLearnMorePages(projectId: string) {
    const { loading, pages, refetch } = this.getProductPages(projectId)
    const tosPage = computed(() => {
      const page = pages.value?.find(
        (page) =>
          page.pageType === 'LearnMorePage' &&
          'type' in page.pageTypeDetails &&
          page.pageTypeDetails.type === 'TOS'
      )
      return page ? transformLearnMorePageFromApi(page) : undefined
    })

    return { loading, tosPage, refetch }
  }

  public getProductIdForProject(
    projectId: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  ) {
    const { result, loading } = useFetch(
      GET_PRODUCT_FOR_PROJECT,
      {
        filter: { projectId: projectId },
      },
      { fetchPolicy }
    )

    const productId: ComputedRef<string> = computed(() => {
      return !loading.value ? result.value?.product?._id : ''
    })

    return { loading, productId }
  }

  public getPage(pageId: string) {
    const { result, loading } = useFetch(GET_PRODUCT_PAGE_BY_ID, {
      filter: { _id: pageId },
    })

    const page: ComputedRef<ProductPage | undefined> = computed(() => {
      if (!loading.value && result.value) {
        const pageResult = result.value?.productPage
        return pageResult ? transformPageFromApi(pageResult) : undefined
      }

      return undefined
    })

    return { loading, page }
  }

  public getProjectDefaultLanguage(
    projectId: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  ) {
    const { result, loading } = useFetch(
      GET_DEFAULT_LANGUAGE_FOR_PROJECT,
      {
        filter: { projectId: projectId },
      },
      { fetchPolicy }
    )
    const productDefaultLanguage: ComputedRef<string> = computed(() => {
      return !loading.value ? result.value?.product?.defaultLanguage : ''
    })

    return { loading, productDefaultLanguage }
  }

  /**
   * Updates a product.
   */
  public async saveProduct(projectID: string, menu: ApiProductMenu[]) {
    const { mutate, loading, error } = useAction(SAVE_PRODUCT)

    const result = await mutate({
      product: {
        menu: menu,
        projectId: projectID,
      },
    })

    const { networkErrorMessage } = useErrors(error)

    return { result, loading, networkErrorMessage }
  }

  /**
   * Creates or updates a page.
   */
  public async savePage(
    newPage: ProductPage,
    pageType: PageType,
    productId: string,
    projectId?: string
  ) {
    const { mutate, loading, error } = useAction(CREATE_PAGE)
    const page = await mutate({
      productPage: {
        productId: productId,
        ...transformPageForApi(newPage, pageType, projectId),
      },
    })
    const { networkErrorMessage } = useErrors(error)

    return { page, loading, networkErrorMessage }
  }

  public useMutations() {
    return {
      saveProductChanges: useAction(SAVE_PRODUCT_CHANGES),
      previewProduct: useAction(PREVIEW_PRODUCT),
      publishProduct: useAction(PUBLISH_PRODUCT),
      removePage: useAction(REMOVE_PAGE),
      removeExtension: useAction(DELETE_EXTENSION),
      updateExtension: useAction(UPDATE_EXTENSION),
      createExtension: useAction(CREATE_EXTENSION),
    }
  }
}

export default new ProductBuilderService()
