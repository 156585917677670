import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_HelperBubble = _resolveComponent("HelperBubble")!
  const _component_BckOfferCard = _resolveComponent("BckOfferCard")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckButton = _resolveComponent("BckButton")!
  const _component_BckGridColumn = _resolveComponent("BckGridColumn")!
  const _component_BckGrid = _resolveComponent("BckGrid")!
  const _component_Disclaimer = _resolveComponent("Disclaimer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      _ctx.seamlessPreviewStyle.container(
        _ctx.showHelpers,
        _ctx.type === 'overflowCarousel' && _ctx.cardDesign !== 'e2eCard',
        _ctx.height,
        _ctx.sizes?.fonts?.content
      )
    ),
    id: "seamless-preview-0"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.seamlessPreviewStyle.wrapper),
      id: "seamless-preview-1"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.seamlessPreviewStyle.headerPlaceholder),
        id: "seamless-preview-2"
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.seamlessPreviewStyle.addressBar(_ctx.sizes?.fonts?.addressBar)),
        id: "seamless-preview-3"
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.projectDomainName), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.seamlessPreviewStyle.previewProjectBackgroundImage(_ctx.projectBackgroundImage, _ctx.flowBuilder)),
        id: "seamless-preview-wanted"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.seamlessPreviewStyle.headerIcons(_ctx.sizes?.headerIcons)),
          id: "seamless-preview-4"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.margin('auto no')),
            id: "seamless-preview-5"
          }, [
            (!!_ctx.projectLogo)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.projectLogo,
                  alt: "projectLogo",
                  class: "header-icon"
                }, null, 8, _hoisted_1))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, "Logo goes here"))
          ], 2),
          _createVNode(_component_BckIcon, {
            "icon-name": "settingsIcon",
            width: "23px",
            height: "23px",
            class: "header-icon settings-icon"
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.seamlessPreviewStyle.title(_ctx.readMorePreview, _ctx.seamlessColors.branding, _ctx.sizes?.fonts?.title)),
          id: "seamless-preview-6"
        }, [
          _createTextVNode(_toDisplayString(_ctx.title || 'Page Title: Maximum 65 characters including spaces.') + " ", 1),
          _createVNode(_component_HelperBubble, {
            "bubble-text": "Page Title",
            active: !!_ctx.title,
            left: "-145px"
          }, null, 8, ["active"])
        ], 2),
        (_ctx.showDescription)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "seamless-preview-7",
              class: _normalizeClass(_ctx.seamlessPreviewStyle.description)
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(!_ctx.description && _ctx.showHelperTexts
                ? 'Page Subtitle: Maximum 90 characters including spaces, max. 2 lines.'
                : _ctx.description), 1),
              _createVNode(_component_HelperBubble, {
                "bubble-text": "Description",
                active: !!_ctx.description,
                left: "-154px"
              }, null, 8, ["active"])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.type === 'carousel')
          ? (_openBlock(), _createBlock(_component_HelperBubble, {
              key: 1,
              "bubble-text": "Cards",
              active: !!_ctx.offers.filter((offer) => _ctx.values(offer).some((field) => field)).length,
              top: "43%",
              left: "-100px"
            }, null, 8, ["active"]))
          : _createCommentVNode("", true),
        (_ctx.type === 'overflowCarousel' && _ctx.cardDesign !== 'e2eCard')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              id: "seamless-preview-8",
              class: _normalizeClass([_ctx.seamlessPreviewStyle.arrow('left')]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollCards('left')))
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.offers && _ctx.offers.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              id: "seamless-preview-9",
              ref: "cards",
              class: _normalizeClass(_ctx.seamlessPreviewStyle.offersWrapper(_ctx.type, _ctx.offers.length === 1, _ctx.sizes.offersMarginLeft, _ctx.cardDesign))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offers, (offer, key) => {
                return (_openBlock(), _createBlock(_component_BckOfferCard, {
                  key: key,
                  offer: offer,
                  templateKey: 'BUY',
                  margin: "no tiny no no",
                  class: _normalizeClass(['offer-card', _ctx.seamlessPreviewStyle.offerCard(_ctx.sizes.offerCard)]),
                  "cta-color": _ctx.seamlessColors.ctaButton,
                  "cta-text-color": _ctx.seamlessColors.ctaText,
                  projectID: _ctx.project?.id,
                  cardDesign: _ctx.cardDesign
                }, null, 8, ["offer", "class", "cta-color", "cta-text-color", "projectID", "cardDesign"]))
              }), 128))
            ], 2))
          : (_ctx.readMorePreview)
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                id: "seamless-preview-10",
                class: _normalizeClass(_ctx.seamlessPreviewStyle.tableItemsContainer(_ctx.buttons.length === 0, _ctx.sizes?.fonts?.tableItems))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readMoreRows, (tableItem, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    ref_for: true,
                    ref: 
              (el) => {
                _ctx.itemsRefs[index] = el
              }
            ,
                    class: _normalizeClass(_ctx.readMoreStyle.tableItemWrapper)
                  }, [
                    _createVNode(_component_BckText, {
                      size: "small",
                      weight: "bold",
                      margin: "no no xxSmall no"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tableItem.subtitle || `Table Item ${index + 1}`), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_HelperBubble, {
                      "bubble-text": `Table Item ${index + 1}`,
                      active: !!(tableItem.description || tableItem.subtitle),
                      top: "-5px",
                      left: "-203px"
                    }, null, 8, ["bubble-text", "active"]),
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formatDescription(tableItem.description), (descItem, i) => {
                        return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(descItem), 1))
                      }), 128))
                    ])
                  ], 2))
                }), 128))
              ], 2))
            : (_ctx.type === 'learnMore')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 5,
                  id: "seamless-preview-11",
                  class: _normalizeClass(_ctx.seamlessPreviewStyle.learnMorePage),
                  innerHTML: _ctx.sanitizedText
                }, null, 10, _hoisted_4))
              : (_openBlock(), _createElementBlock("div", {
                  key: 6,
                  id: "seamless-preview-12",
                  class: _normalizeClass(_ctx.seamlessPreviewStyle.offersWrapper(_ctx.carouselView, true))
                }, [
                  _createVNode(_component_BckOfferCard, {
                    margin: "no tiny",
                    class: _normalizeClass(['offer-card', _ctx.seamlessPreviewStyle.offerCard(_ctx.sizes.offerCard)]),
                    "project-i-d": _ctx.project?.id
                  }, null, 8, ["class", "project-i-d"])
                ], 2)),
        (_ctx.type === 'overflowCarousel' && _ctx.cardDesign !== 'e2eCard')
          ? (_openBlock(), _createElementBlock("div", {
              key: 7,
              id: "seamless-preview-13",
              class: _normalizeClass(_ctx.seamlessPreviewStyle.linearGradient)
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.type === 'overflowCarousel' && _ctx.cardDesign !== 'e2eCard')
          ? (_openBlock(), _createElementBlock("div", {
              key: 8,
              id: "seamless-preview-14",
              class: _normalizeClass(_ctx.seamlessPreviewStyle.arrow('right')),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollCards('right')))
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.buttons.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 9,
              id: "seamless-preview-16",
              class: _normalizeClass(_ctx.seamlessPreviewStyle.buttonsContainer(_ctx.sizes.buttonsStyle, _ctx.sizes.navBarBorderRadius))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
                return (_openBlock(), _createBlock(_component_BckButton, {
                  key: button,
                  "button-style": "secondary",
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(button), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        (!_ctx.readMorePreview && !_ctx.funnelOnly)
          ? (_openBlock(), _createElementBlock("div", {
              key: 10,
              id: "seamless-preview-17",
              class: _normalizeClass(
            _ctx.seamlessPreviewStyle.navBar(
              _ctx.seamlessColors.branding,
              _ctx.seamlessColors.menuIcons,
              _ctx.sizes?.fonts?.navBar,
              _ctx.sizes?.navBarIcons,
              _ctx.sizes?.navBarBorderRadius
            )
          )
            }, [
              _createVNode(_component_BckGrid, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navMenuItems, (menuItem, index) => {
                    return (_openBlock(), _createBlock(_component_BckGridColumn, {
                      key: index,
                      size: `${12 / _ctx.navMenuItems.length}/12`,
                      align: "center",
                      padding: "no",
                      class: _normalizeClass(_ctx.seamlessPreviewStyle.tab),
                      id: "seamless-preview-18"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_BckIcon, {
                            class: "nav-bar-icon",
                            id: "seamless-preview-19",
                            "icon-name": menuItem.icon,
                            stroke: _ctx.seamlessColors.menuIcons,
                            fill: "none",
                            "view-box": "-3 -7 33 33"
                          }, null, 8, ["icon-name", "stroke"])
                        ]),
                        _createTextVNode(" " + _toDisplayString(menuItem.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["size", "class"]))
                  }), 128))
                ]),
                _: 1
              })
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createVNode(_component_Disclaimer)
  ], 2))
}