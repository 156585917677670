
import { defineComponent, PropType } from 'vue'
import { getLanguageFromKey } from '@/utils/languagesHelper'

export default defineComponent({
  props: {
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedLanguage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  setup() {
    return { getLanguageFromKey }
  },
})
